// 入库单
export const WAREHOUSE_IN_VIEW_ROUTE = {
    name: 'warehouseInView',
    path: '/warehouse/in',
    meta: { name: '入库单', keepAlive: true, uid: 'warehouseInView' },
    component: () =>
        import ('@/views/warehouse/in')
}

export const WAREHOUSE_IN_DETAIL_SALES_VIEW_ROUTE = {
    name: 'warehouseInDetailSalesView',
    path: '/warehouse/in/detail/sales/:id',
    meta: { name: '销售退货入库单查看', keepAlive: true, uid: 'warehouseInDetailSalesView' },
    component: () =>
        import ('@/views/warehouse/in/detailSales')
}

export const WAREHOUSE_IN_DETAIL_PURCHASE_VIEW_ROUTE = {
    name: 'warehouseInDetailPurchaseView',
    path: '/warehouse/in/detail/purchase/:id',
    meta: { name: '采购入库单查看', keepAlive: true, uid: 'warehouseInDetailPurchaseView' },
    component: () =>
        import ('@/views/warehouse/in/detailPurchase')
}

// 出库单
export const WAREHOUSE_OUT_VIEW_ROUTE = {
    name: 'warehouseOutView',
    path: '/warehouse/out',
    meta: { name: '出库单', keepAlive: true, uid: 'warehouseOutView' },
    component: () =>
        import ('@/views/warehouse/out')
}

export const WAREHOUSE_OUT_DETAIL_SALES_VIEW_ROUTE = {
    name: 'warehouseOutDetailSalesView',
    path: '/warehouse/out/detail/sales/:id',
    meta: { name: '销售出库单查看', keepAlive: true, uid: 'warehouseOutDetailSalesView' },
    component: () =>
        import ('@/views/warehouse/out/detailSales')
}

export const WAREHOUSE_OUT_DETAIL_PURCHASE_VIEW_ROUTE = {
    name: 'warehouseOutDetailPurchaseView',
    path: '/warehouse/out/detail/purchase/:id',
    meta: { name: '采购退货出库单查看', keepAlive: true, uid: 'warehouseOutDetailPurchaseView' },
    component: () =>
        import ('@/views/warehouse/out/detailPurchase')
}

// 调整单
export const WAREHOUSE_IN_ADJUST_VIEW_ROUTE = {
    name: 'warehouseInAdjustView',
    path: '/warehouse/in/adjust',
    meta: { name: '采购入库调整单', keepAlive: true, uid: 'warehouseInAdjustView' },
    component: () =>
        import ('@/views/warehouse/in/adjust')
}
export const WAREHOUSE_IN_ADJUST_ADD_VIEW_ROUTE = {
    name: 'warehouseInAdjustAddView',
    path: '/warehouse/in/adjust/add',
    meta: { name: '采购入库调整单新增', keepAlive: true, uid: 'warehouseInAdjustAddView' },
    component: () =>
        import ('@/views/warehouse/in/adjust/add')
}
