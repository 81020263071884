import { createRouter, createWebHashHistory } from 'vue-router'
import * as routeMetaInfo from './routeMetaInfo'
import * as cashierRouteMetaInfo from './cashierRouteMetaInfo'
import * as arapRouteMetaInfo from './arapRouteMetaInfo'
// import * as purchaseRouteMetaInfo from './purchaseRouteMetaInfo'
// import * as stockRouteMetaInfo from './stockRouteMetaInfo'
import * as taxMetaInfo from './taxMetaInfo'
import * as templateMetaInfo from './templateMetaInfo'
import * as settingsMetaInfo from './settingsMetaInfo'
import * as absorptionRouteMetaInfo from './absorptionRouteMetaInfo'
import * as dailyMetaInfo from './dailyMetaInfo'
import * as purchaseMetaInfo from './purchaseMetaInfo'
import * as salesMetaInfo from './salesMetaInfo'
import * as warehouseMetaInfo from './warehouseMetaInfo'

const routes = [{
        path: '/',
        name: 'layout',
        meta: { uid: 'layout' },
        component: () =>
            import ('@/views/layout'),
        redirect: { name: 'dashboardView' },
        children: [
            // 我的办公
            dailyMetaInfo.COST_REIMBURSE_VIEW_ROUTE,
            dailyMetaInfo.COST_REIMBURSE_OFFICE_DETAIL_VIEW_ROUTE,
            dailyMetaInfo.COST_REIMBURSE_SERVE_DETAIL_VIEW_ROUTE,
            dailyMetaInfo.COST_REIMBURSE_TRANSPORT_DETAIL_VIEW_ROUTE,
            dailyMetaInfo.COST_REIMBURSE_HOTEL_DETAIL_VIEW_ROUTE,
            dailyMetaInfo.COST_REIMBURSE_LOGISTICS_DETAIL_VIEW_ROUTE,
            dailyMetaInfo.COST_REIMBURSE_COMMUNICATION_DETAIL_VIEW_ROUTE,
            dailyMetaInfo.COST_REIMBURSE_OIL_DETAIL_VIEW_ROUTE,
            dailyMetaInfo.COST_REIMBURSE_WAY_DETAIL_VIEW_ROUTE,
            dailyMetaInfo.COST_REIMBURSE_MAINTENANCE_DETAIL_VIEW_ROUTE,
            dailyMetaInfo.COST_REIMBURSE_OTHER_DETAIL_VIEW_ROUTE,
            dailyMetaInfo.EMPLOYEE_LOAN_VIEW_ROUTE,
            dailyMetaInfo.EMPLOYEE_LOAN_DETAIL_VIEW_ROUTE,
            dailyMetaInfo.EMPLOYEE_LOAN_REPAY_VIEW_ROUTE,
            dailyMetaInfo.EMPLOYEE_LOAN_REPAY_DETAIL_VIEW_ROUTE,
            dailyMetaInfo.PAY_APPLY_VIEW_ROUTE,
            dailyMetaInfo.PAY_APPLY_TRANSPORT_DETAIL_VIEW_ROUTE,
            dailyMetaInfo.PAY_APPLY_AD_DETAIL_VIEW_ROUTE,
            // 采购管理
            purchaseMetaInfo.PURCHASE_VIEW_ROUTE,
            purchaseMetaInfo.PURCHASE_DETAIL_VIEW_ROUTE,
            purchaseMetaInfo.PURCHASE_INVOICE_VIEW_ROUTE,
            purchaseMetaInfo.PURCHASE_INVOICE_DETAIL_VIEW_ROUTE,
            purchaseMetaInfo.FINANCE_DATA_VIEW_ROUTE,
            // 销售管理
            salesMetaInfo.SALES_VIEW_ROUTE,
            salesMetaInfo.SALES_DETAIL_VIEW_ROUTE,
            salesMetaInfo.SALES_INVOICE_VIEW_ROUTE,
            salesMetaInfo.SALES_INVOICE_DETAIL_VIEW_ROUTE,
            salesMetaInfo.FINANCE_DATA_SALES_VIEW_ROUTE,
            // 仓储管理
            warehouseMetaInfo.WAREHOUSE_IN_VIEW_ROUTE,
            warehouseMetaInfo.WAREHOUSE_IN_DETAIL_SALES_VIEW_ROUTE,
            warehouseMetaInfo.WAREHOUSE_IN_DETAIL_PURCHASE_VIEW_ROUTE,
            warehouseMetaInfo.WAREHOUSE_OUT_VIEW_ROUTE,
            warehouseMetaInfo.WAREHOUSE_OUT_DETAIL_SALES_VIEW_ROUTE,
            warehouseMetaInfo.WAREHOUSE_OUT_DETAIL_PURCHASE_VIEW_ROUTE,
            warehouseMetaInfo.WAREHOUSE_IN_ADJUST_VIEW_ROUTE,
            warehouseMetaInfo.WAREHOUSE_IN_ADJUST_ADD_VIEW_ROUTE,
            // 待摊管理
            absorptionRouteMetaInfo.PAY_APPLY_ITEM_VIEW_ROUTE,
            absorptionRouteMetaInfo.COST_ABSORPTION_WAIT_VIEW_ROUTE,
            absorptionRouteMetaInfo.COST_ABSORPTION_WAIT_ADD_VIEW_ROUTE,
            absorptionRouteMetaInfo.COST_ABSORPTION_WAIT_DETAIL_VIEW_ROUTE,
            absorptionRouteMetaInfo.COST_ABSORPTION_VIEW_ROUTE,
            absorptionRouteMetaInfo.COST_ABSORPTION_DETAIL_VIEW_ROUTE,
            absorptionRouteMetaInfo.PAY_APPLY_ITEM_PURCHASE_VIEW_ROUTE,
            absorptionRouteMetaInfo.PURCHASE_ABSORPTION_VIEW_ROUTE,
            absorptionRouteMetaInfo.PURCHASE_ABSORPTION_ADD_VIEW_ROUTE,
            absorptionRouteMetaInfo.PURCHASE_ABSORPTION_DETAIL_VIEW_ROUTE,
            // 税费管理
            taxMetaInfo.INVOICE_VIEW_ROUTE,
            taxMetaInfo.INVOICE_ADD_VIEW_ROUTE,
            taxMetaInfo.INVOICE_DETAIL_VIEW_ROUTE,
            taxMetaInfo.TAX_INVOICE_APPLY_VIEW_ROUTE,
            taxMetaInfo.TAX_INVOICE_APPLY_DETAIL_VIEW_ROUTE,
            taxMetaInfo.INVOICE_PROJECT_VIEW_ROUTE,
            taxMetaInfo.INVOICE_PROJECT_ADD_VIEW_ROUTE,
            taxMetaInfo.INVOICE_PROJECT_EDIT_VIEW_ROUTE,
            taxMetaInfo.INVOICE_PROJECT_DETAIL_VIEW_ROUTE,
            // // 往来管理
            arapRouteMetaInfo.AR_VIEW_ROUTE,
            arapRouteMetaInfo.AR_DETAIL_VIEW_ROUTE,
            arapRouteMetaInfo.AP_VIEW_ROUTE,
            arapRouteMetaInfo.AP_DETAIL_VIEW_ROUTE,
            arapRouteMetaInfo.AP_CONFIRM_VIEW_ROUTE,
            arapRouteMetaInfo.AP_CONFIRM_DETAIL_VIEW_ROUTE,
            arapRouteMetaInfo.AP_CONFIRM_ADD_VIEW_ROUTE,
            arapRouteMetaInfo.AP_CONFIRM_EDIT_VIEW_ROUTE,
            arapRouteMetaInfo.AR_CONFIRM_VIEW_ROUTE,
            arapRouteMetaInfo.AR_CONFIRM_DETAIL_VIEW_ROUTE,
            arapRouteMetaInfo.AR_CONFIRM_ADD_VIEW_ROUTE,
            arapRouteMetaInfo.AR_CONFIRM_EDIT_VIEW_ROUTE,
            // // 出纳管理
            cashierRouteMetaInfo.SETTLEMENT_METHOD_VIEW_ROUTE, // 结算方式
            cashierRouteMetaInfo.SETTLEMENT_METHOD_DETAIL_VIEW_ROUTE,
            cashierRouteMetaInfo.SETTLEMENT_METHOD_ADD_VIEW_ROUTE,
            cashierRouteMetaInfo.SETTLEMENT_METHOD_EDIT_VIEW_ROUTE,
            cashierRouteMetaInfo.ACCOUNT_VIEW_ROUTE, // 账户
            cashierRouteMetaInfo.ACCOUNT_DETAIL_VIEW_ROUTE,
            cashierRouteMetaInfo.ACCOUNT_ADD_VIEW_ROUTE,
            cashierRouteMetaInfo.ACCOUNT_EDIT_VIEW_ROUTE,
            cashierRouteMetaInfo.ACCOUNT_INIT_VIEW_ROUTE,
            cashierRouteMetaInfo.PAY_CONFIRM_VIEW_ROUTE, // 应付执行单
            cashierRouteMetaInfo.PAY_CONFIRM_DETAIL_VIEW_ROUTE,
            cashierRouteMetaInfo.PAY_BATCH_VIEW_ROUTE, // 确认单
            cashierRouteMetaInfo.PAY_BATCH_ADD_VIEW_ROUTE,
            cashierRouteMetaInfo.PAY_BATCH_DETAIL_VIEW_ROUTE,
            cashierRouteMetaInfo.REPAY_BATCH_VIEW_ROUTE,
            cashierRouteMetaInfo.REPAY_BATCH_ADD_VIEW_ROUTE,
            cashierRouteMetaInfo.REPAY_BATCH_DETAIL_VIEW_ROUTE,
            cashierRouteMetaInfo.PAY_VIEW_ROUTE,
            cashierRouteMetaInfo.PAY_ADD_VIEW_ROUTE,
            cashierRouteMetaInfo.PAY_DETAIL_VIEW_ROUTE,
            cashierRouteMetaInfo.REPAY_CONFIRM_VIEW_ROUTE, // 应收执行单
            cashierRouteMetaInfo.REPAY_CONFIRM_DETAIL_VIEW_ROUTE,
            cashierRouteMetaInfo.REPAY_VIEW_ROUTE,
            cashierRouteMetaInfo.REPAY_ADD_VIEW_ROUTE,
            cashierRouteMetaInfo.REPAY_DETAIL_VIEW_ROUTE,
            cashierRouteMetaInfo.PAY_ITEM_CASH_VIEW_ROUTE,
            cashierRouteMetaInfo.PAY_ITEM_BANK_VIEW_ROUTE,
            // 凭证模板
            templateMetaInfo.TEMPLATE_ACCOUNTING_BOOK_VIEW,
            templateMetaInfo.TEMPLATE_ACCOUNTING_BOOK_DETAIL_VIEW,
            templateMetaInfo.TEMPLATE_CATEGORY_VIEW,
            templateMetaInfo.TEMPLATE_CATEGORY_DETAIL_VIEW,
            templateMetaInfo.TEMPLATE_CATEGORY_EDIT_VIEW,
            templateMetaInfo.TEMPLATE_VIEW,
            templateMetaInfo.TEMPLATE_DETAIL_VIEW,
            templateMetaInfo.TEMPLATE_VOUCHER_LOG_VIEW,
            // // 账簿
            routeMetaInfo.ACCOUNTING_BOOK_VIEW_ROUTE,
            routeMetaInfo.ACCOUNTING_BOOK_ADD_VIEW_ROUTE,
            routeMetaInfo.ACCOUNTING_BOOK_EDIT_VIEW_ROUTE,
            routeMetaInfo.ACCOUNTING_BOOK_DETAIL_VIEW_ROUTE,
            // 科目表
            routeMetaInfo.SUBJECT_TABLE_VIEW_ROUTE,
            routeMetaInfo.SUBJECT_TABLE_ADD_VIEW_ROUTE,
            routeMetaInfo.SUBJECT_TABLE_EDIT_VIEW_ROUTE,
            routeMetaInfo.SUBJECT_TABLE_DETAIL_VIEW_ROUTE,
            // // 科目
            routeMetaInfo.SUBJECT_VIEW_ROUTE,
            routeMetaInfo.SUBJECT_ADD_VIEW_ROUTE,
            routeMetaInfo.SUBJECT_EDIT_VIEW_ROUTE,
            routeMetaInfo.SUBJECT_DETAIL_VIEW_ROUTE,
            routeMetaInfo.COMMON_SUBJECT_VIEW_ROUTE,
            // // 财务管理
            // // 汇率方案
            routeMetaInfo.EXCHANGE_PLAN_DETAIL_VIEW_ROUTE,
            routeMetaInfo.EXCHANGE_PLAN_EDIT_VIEW_ROUTE,
            // // 汇率
            routeMetaInfo.EXCHANGE_RATE_EDIT_VIEW_ROUTE,
            // 初始化
            routeMetaInfo.INIT_SUBJECT_VIEW_ROUTE,
            routeMetaInfo.INIT_CASH_FLOW_VIEW_ROUTE,
            routeMetaInfo.INIT_ACCOUNTING_BOOK_VIEW_ROUTE,
            routeMetaInfo.INIT_ACCOUNTING_BOOK_DETAIL_VIEW_ROUTE,
            // 凭证设置
            routeMetaInfo.VOUCHER_SETTINGS_EDIT_VIEW_ROUTE,
            routeMetaInfo.VOUCHER_SETTINGS_DETAIL_VIEW_ROUTE,
            // 凭证
            routeMetaInfo.VOUCHER_VIEW_ROUTE,
            routeMetaInfo.VOUCHER_ADD_VIEW_ROUTE,
            routeMetaInfo.VOUCHER_DETAIL_VIEW_ROUTE,
            routeMetaInfo.VOUCHER_EDIT_VIEW_ROUTE,
            routeMetaInfo.VOUCHER_ITEM_VIEW_ROUTE,
            routeMetaInfo.VOUCHER_ITEM_GENERAL_LEDGER_VIEW_ROUTE,
            routeMetaInfo.VOUCHER_ITEM_FAST_VIEW_ROUTE,
            // 凭证 - 摘要分类
            routeMetaInfo.VOUCHER_ABSTRACT_CATEGORY_VIEW_ROUTE,
            routeMetaInfo.VOUCHER_ABSTRACT_CATEGORY_ADD_VIEW_ROUTE,
            routeMetaInfo.VOUCHER_ABSTRACT_CATEGORY_EDIT_VIEW_ROUTE,
            routeMetaInfo.VOUCHER_ABSTRACT_CATEGORY_DETAIL_VIEW_ROUTE,
            // 凭证 - 摘要库
            routeMetaInfo.VOUCHER_ABSTRACT_VIEW_ROUTE,
            routeMetaInfo.VOUCHER_ABSTRACT_ADD_VIEW_ROUTE,
            routeMetaInfo.VOUCHER_ABSTRACT_EDIT_VIEW_ROUTE,
            routeMetaInfo.VOUCHER_ABSTRACT_DETAIL_VIEW_ROUTE,
            // 结转
            // 结转研发方案、记录
            routeMetaInfo.CARRY_OVER_PLAN_COST_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_COST_ADD_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_COST_EDIT_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_COST_DETAIL_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_RECORD_COST_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_RECORD_COST_DETAIL_VIEW_ROUTE,
            // 结转增值税、记录
            routeMetaInfo.CARRY_OVER_PLAN_ADD_TAX_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_ADD_TAX_ADD_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_ADD_TAX_EDIT_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_ADD_TAX_DETAIL_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_RECORD_ADD_TAX_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_RECORD_ADD_TAX_DETAIL_VIEW_ROUTE,
            // 结转损益、记录
            routeMetaInfo.CARRY_OVER_PLAN_PROFIT_LOSS_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_PROFIT_LOSS_ADD_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_PROFIT_LOSS_EDIT_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_PROFIT_LOSS_DETAIL_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_RECORD_PROFIT_LOSS_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_RECORD_PROFIT_LOSS_DETAIL_VIEW_ROUTE,
            // 结转业所得税方案、记录
            routeMetaInfo.CARRY_OVER_PLAN_INCOME_TAX_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_INCOME_TAX_ADD_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_INCOME_TAX_EDIT_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_INCOME_TAX_DETAIL_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_RECORD_INCOME_TAX_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_RECORD_INCOME_TAX_DETAIL_VIEW_ROUTE,
            // 结转本年利润、记录
            routeMetaInfo.CARRY_OVER_PLAN_YEAR_PROFIT_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_YEAR_PROFIT_ADD_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_YEAR_PROFIT_EDIT_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_PLAN_YEAR_PROFIT_DETAIL_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_RECORD_YEAR_PROFIT_VIEW_ROUTE,
            routeMetaInfo.CARRY_OVER_RECORD_YEAR_PROFIT_DETAIL_VIEW_ROUTE,
            // 计提
            // 计提企业所得税方案、记录
            routeMetaInfo.EXTRACT_BASE_NUMBER_INCOME_TAX_PROVISION_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_BASE_NUMBER_INCOME_TAX_PROVISION_ADD_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_BASE_NUMBER_INCOME_TAX_PROVISION_EDIT_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_BASE_NUMBER_INCOME_TAX_PROVISION_DETAIL_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_PLAN_INCOME_TAX_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_PLAN_INCOME_TAX_ADD_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_PLAN_INCOME_TAX_EDIT_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_PLAN_INCOME_TAX_DETAIL_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_RECORD_INCOME_TAX_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_RECORD_INCOME_TAX_DETAIL_VIEW_ROUTE,
            // 计提盈余公积方案、记录
            routeMetaInfo.EXTRACT_BASE_NUMBER_SURPLUS_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_PLAN_SURPLUS_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_PLAN_SURPLUS_ADD_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_PLAN_SURPLUS_EDIT_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_PLAN_SURPLUS_DETAIL_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_RECORD_SURPLUS_VIEW_ROUTE,
            routeMetaInfo.EXTRACT_RECORD_SURPLUS_DETAIL_VIEW_ROUTE,
            // 结账
            routeMetaInfo.SETTLE_RECORD_VIEW_ROUTE,
            // 现金流量 - 分类
            routeMetaInfo.CASH_FLOW_CATEGORY_VIEW_ROUTE,
            routeMetaInfo.CASH_FLOW_CATEGORY_ADD_VIEW_ROUTE,
            routeMetaInfo.CASH_FLOW_CATEGORY_EDIT_VIEW_ROUTE,
            routeMetaInfo.CASH_FLOW_CATEGORY_DETAIL_VIEW_ROUTE,
            // 现金流量 - 项目
            routeMetaInfo.CASH_FLOW_PROJECT_VIEW_ROUTE,
            routeMetaInfo.CASH_FLOW_PROJECT_ADD_VIEW_ROUTE,
            routeMetaInfo.CASH_FLOW_PROJECT_EDIT_VIEW_ROUTE,
            routeMetaInfo.CASH_FLOW_PROJECT_DETAIL_VIEW_ROUTE,
            // 现金流量 - 定义
            routeMetaInfo.CASH_FLOW_DEFINITION_VIEW_ROUTE,
            // 现金流量 - 列表
            routeMetaInfo.CASH_FLOW_VIEW_ROUTE,
            routeMetaInfo.CASH_FLOW_EDIT_VIEW_ROUTE,
            routeMetaInfo.CASH_FLOW_DETAIL_VIEW_ROUTE,
            // 现金流量 - 明细
            routeMetaInfo.CASH_FLOW_ITEM_VIEW_ROUTE,
            // 现金流量 - 汇总
            routeMetaInfo.CASH_FLOW_ITEM_REPORT_VIEW_ROUTE,
            // 财务报表
            routeMetaInfo.REPORT_BALANCE_SHEET_VIEW_ROUTE,
            routeMetaInfo.REPORT_BALANCE_SHEET_FORMULA_VIEW_ROUTE,
            routeMetaInfo.REPORT_PROFITE_VIEW_ROUTE,
            routeMetaInfo.REPORT_PROFITE_FORMULA_VIEW_ROUTE,
            routeMetaInfo.REPORT_CASH_FLOW_VIEW_ROUTE,
            routeMetaInfo.REPORT_CASH_FLOW_FORMULA_VIEW_ROUTE,
            routeMetaInfo.REPORT_EQUITY_CHANGE_VIEW_ROUTE,
            // 自定义报表
            routeMetaInfo.REPORT_CUSTOM_CATEGORY_VIEW_ROUTE,
            routeMetaInfo.REPORT_CUSTOM_CATEGORY_ADD_VIEW_ROUTE,
            routeMetaInfo.REPORT_CUSTOM_CATEGORY_EDIT_VIEW_ROUTE,
            routeMetaInfo.REPORT_CUSTOM_CATEGORY_DETAIL_VIEW_ROUTE,
            routeMetaInfo.REPORT_CUSTOM_VIEW_ROUTE,
            routeMetaInfo.REPORT_CUSTOM_ADD_VIEW_ROUTE,
            routeMetaInfo.REPORT_CUSTOM_EDIT_VIEW_ROUTE,
            routeMetaInfo.REPORT_CUSTOM_DETAIL_VIEW_ROUTE,
            // // 财务报表 - 分析
            routeMetaInfo.REPORT_ANALYSIS_BALANCE_SHEET_TREND_VIEW_ROUTE,
            routeMetaInfo.REPORT_ANALYSIS_BALANCE_SHEET_YEAR_ON_YEAR_VIEW_ROUTE,
            routeMetaInfo.REPORT_ANALYSIS_BALANCE_SHEET_ITEM_VIEW_ROUTE,
            routeMetaInfo.REPORT_ANALYSIS_PROFIT_TREND_VIEW_ROUTE,
            routeMetaInfo.REPORT_ANALYSIS_PROFIT_YEAR_ON_YEAR_VIEW_ROUTE,
            routeMetaInfo.REPORT_ANALYSIS_PROFIT_ITEM_TREND_VIEW_ROUTE,
            routeMetaInfo.REPORT_ANALYSIS_PROFIT_ITEM_YEAR_ON_YEAR_VIEW_ROUTE,
            routeMetaInfo.REPORT_ANALYSIS_PROFIT_ITEM_VIEW_ROUTE,
            // // 基础设置
            settingsMetaInfo.PERMISSION_USER_VIEW_ROUTE,
            settingsMetaInfo.PERMISSION_USER_DETAIL_VIEW_ROUTE,
            settingsMetaInfo.PERMISSION_USER_EDIT_VIEW_ROUTE,
            settingsMetaInfo.PERMISSION_USER_ADD_VIEW_ROUTE,
            settingsMetaInfo.PERMISSION_ROLE_VIEW_ROUTE,
            // 首页
            routeMetaInfo.DASHBOARD_VIEW_ROUTE
        ]
    },
    routeMetaInfo.LOGIN_VIEW_ROUTE
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path === routeMetaInfo.LOGIN_VIEW_ROUTE.path) {
        next()
    } else {
        const token = sessionStorage.getItem('token')
        if (!token) {
            next(routeMetaInfo.LOGIN_VIEW_ROUTE.path)
        } else {
            next()
        }
    }
})

export default router